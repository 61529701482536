//
//
//
//
//
//
//
//
//
//
//
//

import componentMinxins from '../ComponentMinxins'

export default {
  mixins: [componentMinxins],
  name: "DateTime",
  components: {},
  props:{
    value: {
      default: null
    },
    format:{
      type: String,
      default: 'yyyy-MM-dd HH:mm'
    },
    placeholder:{
      type: String,
      default: '请选择日期时间'
    },
    readerMode: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    type(){
      switch (this.format){
        case 'yyyy': return 'year';
        case 'yyyy-MM': return 'month';
        case 'yyyy-MM-dd': return 'date';
        case 'yyyy-MM-dd HH:mm': return 'datetime';
        default: return 'datetime';
      }
    },
  },
  data() {
    return {}
  },
  methods: {}
}
